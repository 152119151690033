<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="card-title">
              <i class="bi bi-thermometer-half"></i> {{ temp }}&#8451;
              <i class="bi bi-wind ml-4"></i> {{ windspeed }}
            </h5>
            <p class="card-subtitle mb-1 text-muted">
              <i class="bi bi-tornado"></i> Böen bis {{ windgust }} -
              <i class="bi bi-exclamation-triangle"></i><el-tooltip :content="alertDescription" placement="bottom"><span v-if="alerts == 1"> 1 Warnung</span><span v-else-if="alerts > 1"> {{ alerts }} Warnungen</span><span v-else> Keine Warnungen</span></el-tooltip>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment-timezone";
import axios from "axios";
import store from "../../../../store";
import { Tooltip } from "element-ui";
Vue.use(Tooltip);
export default {
  components: {},

  data() {
    return {
      alerts: "",
      temp: 0,
      windspeed: 0,
      windgust: 0,
      alertDescription: ''
    };
  },
  created() {
    this.getWeatherData()
    setInterval(() => this.getWeatherData(), 3600000)
    store.watch(
      (state) => state.userlocation,
      () => {
        this.getWeatherData();
      }
    );
  },
  methods: {
    getWeatherData() {
      if(!(localStorage.weatherValidUntil) || localStorage.weatherValidUntil <= Date.now()) {
        var lat
        var long
        if(store.getters.userlocation == "DA") {
          lat = '49.85963'
          long = '8.67440'
        } else if(store.getters.userlocation == "NB") {
          lat = '50.09979'
          long = '8.23273'
        } else if(store.getters.userlocation == "FRA") {
          lat = '50.07154'
          long = '8.64935'
        } else {
          lat = '49.85963'
          long = '8.67440'
        }
      axios
      .get(
        "https://api.openweathermap.org/data/3.0/onecall?lat=" + lat + "&lon=" + long + "&exclude=minutely,hourly,daily&units=metric&lang=de&appid=0b27efad778b2458ef2d32e9f8ae9a4e"
      )
      .then((response) => {
        this.temp = response.data.current.temp.toFixed(1).replace(".", ",");
        this.windspeed =
          (response.data.current.wind_speed * 3.852).toFixed(0) + " km/h";
        this.windgust =
          (response.data.current.wind_gust * 3.852).toFixed(0) + " km/h";
        if (response.data.alerts) {
          this.alerts = response.data.alerts.length;
          localStorage.alerts = response.data.alerts.length;
          var descriptions = []
          for(var i = 0; i < response.data.alerts.length; i++) {
            descriptions.push(response.data.alerts[i].description)
          }
          this.alertDescription = descriptions.join(', ')
          localStorage.alertDescription = this.alertDescription
        } else {
          this.alerts = 0;
          localStorage.alerts = 0;
          if(localStorage.alertDescription) {
            localStorage.removeItem('alertDescription')
          }
        }
        // cache data in local storage
        localStorage.temp = response.data.current.temp.toFixed(1).replace(".", ",");
        localStorage.windspeed = (response.data.current.wind_speed * 3.852).toFixed(0) + " km/h";
        localStorage.windgust = (response.data.current.wind_gust * 3.852).toFixed(0) + " km/h";
        localStorage.weatherValidUntil = (Date.now() + +3600000) //1h
      })
      .catch((e) => {
        console.log('Error Weather API: ' + e)
        this.temp = "-";
        this.windspeed = "-";
        this.windgust = "-";
      });
    } else {
      // use data from cache
      this.temp = localStorage.temp
      this.windspeed = localStorage.windspeed
      this.windgust = localStorage.windgust
      this.alerts = localStorage.alerts
      this.alertDescription = localStorage.alertDescription
    }
    }
  },
};
</script>
<style>
</style>
