<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">
          Mitteilungen
        </h5>
        <p class="card-text mb-1" v-html="hinweis">
        </p>
      </div>
      <div class="card-footer text-muted">
        <div class="row">
          <div class="col-md-auto">
            <i class="bi bi-clock-history"></i> {{ lastUpdate }} Uhr
          </div>
          <div class="col text-right" v-if="role > 1">
            <p-button type="primary" size="sm" title="Bearbeiten" @click="editHinweis()"><i
                class="bi bi-pencil-square"></i></p-button>
          </div>
        </div>
      </div>
    </div>

    <modal :show.sync="showEditDialog" headerClasses="justify-content-center">
      <h5 slot="header" class="title mb-0">Mittleilung bearbeiten</h5>
      <div class="row pt-3 pb-3">
        <div class="col">
          <mc-wysiwyg v-model="hinweisEdit" :hide="editorHiddenElements"></mc-wysiwyg>
        </div>
      </div>
      <template slot="footer">
        <div class="left-side">
          <p-button type="default" link @click="
            showEditDialog = false;
                        ">Abbrechen</p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="success" link @click="saveHinweis">Speichern</p-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment-timezone";
import { Modal } from "src/components/UIComponents";
import store from "../../../../store";
import { db, firebaseApp } from "../../../../firebaseDb";
import { McWysiwyg } from '@mycure/vue-wysiwyg';
export default {
  components: {
    Modal,
    McWysiwyg
  },

  data() {
    return {
      hinweis: "",
      lastUpdate: "",
      location: store.getters.userlocation,
      role: store.getters.userrole,
      hinweisEdit: "",
      showEditDialog: false,
      editorHiddenElements: {
        heading: true,
        table: true,
        strikethrough: true,
      },
    };
  },
  created() {
    firebaseApp.auth().currentUser.getIdTokenResult().then((tokenResult) => {
      this.location = tokenResult.claims.location
      this.role = tokenResult.claims.role
    }).then(() => {
      this.loadData()
      store.watch(
      (state) => state.userlocation,
      () => {
        this.location = store.getters.userlocation;
        this.loadData();
      }
    );
    })
  },
  methods: {
    loadData() {
      const hinweisRef = db.collection("tageshinweis");
      hinweisRef.doc(this.location).get().then((doc) => {
        this.hinweis = doc.data().text
        this.lastUpdate = moment(new Date(doc.data().lastUpdate.seconds * 1000))
          .format("dddd, DD. MMM HH:mm")
          .toString()
      })
    },
    editHinweis() {
      this.hinweisEdit = this.hinweis
      this.showEditDialog = true
    },
    saveHinweis() {
      const hinweisRef = db.collection("tageshinweis");
      hinweisRef.doc(this.location).update({
        text: this.hinweisEdit,
        lastUpdate: new Date()
      }).then(() => {
        this.showEditDialog = false
        this.loadData()
      })
    },
  },
};
</script>
<style></style>
